import { AiOutlineLinkedin } from 'react-icons/ai';
import React from 'react';

export const home = {
  companyName: "Ramaker Solutions",
  companyDescription: [
    "Hallo, ik ben Sjoerd! Als technisch student heb ik tijdens mijn studie een passie ontwikkeld voor data-analyse. Waar dit eerst vooral theoretisch was, wilde ik deze kennis ook praktisch inzetten - zo is Ramaker Solutions ontstaan. Ik focus me op het vertalen van complexe data naar concrete bedrijfsoplossingen.",
    
    "Door analytisch denken te combineren met een praktische aanpak help ik bedrijven hun processen te verbeteren. Ik ontwikkel tools die niet alleen tijd besparen, maar ook menselijke fouten verminderen door slimme data-transformaties. Naast data-analyse voer ik ook marktonderzoek uit wanneer dat nodig is. Hoewel data mijn expertise is, ga ik graag nieuwe uitdagingen aan. Nieuwe vaardigheden leren en efficiënte oplossingen bedenken voor complexe problemen zijn precies waar ik energie van krijg (zoals deze website vanaf nul opbouwen!).",
    
    "Benieuwd wat ik voor jouw bedrijf kan betekenen? Neem gerust contact op via het contactformulier aan het einde van deze pagina!"
  ],
  companyImage: "/images/Ramaker Solutions_E4.png",
  personalBackground: [
    "Al van jongs af aan ben ik een nieuwsgierig persoon. Of het nu ging om mijn vader die in de garage aan het klussen was of de buurman die bezig was, ik kwam altijd even langs om te vragen wat ze aan het doen waren. De welbekende vraag 'Maar waarom dan?' konden ze verwachten totdat alles volledig was uitgelegd. Deze nieuwsgierigheid, interesse in techniek en ondernemende instelling hebben mij uiteindelijk geleid naar de master Industrial Engineering and Management, waar ik momenteel studeer.",    
    "Als betrouwbaar en enthousiast persoon beschik ik over sterke analytische vaardigheden die ik in verschillende sectoren kan toepassen. Ik ben ervan overtuigd dat er altijd mogelijkheden zijn om processen efficiënter in te richten, ongeacht de grootte van het bedrijf. Mijn ambitie is om deze expertise vooral in te zetten in de duurzaamheidssector. Juist daar, waar innovatie zo hard nodig is, wil ik met mijn kennis en vaardigheden het verschil maken.",
    "Verder ben ik een actief persoon die energie haalt uit sporten en activiteiten met vrienden. Deze energie vertaalt zich in een fanatieke instelling waarbij ik er alles aan doe om niet te verliezen. Door mijn nieuwsgierige aard probeer ik graag nieuwe dingen uit. Ook trek ik er graag op uit om te wandelen en mooie plekken te ontdekken - juist die momenten in de natuur laten me zien waarom duurzame oplossingen zo belangrijk zijn."
    ],
  specializations: [
    'Data vertalen naar bruikbare inzichten',
    'Verouderde datasystemen moderniseren',
    'Efficiënte data-structuren ontwikkelen',
    'Automatiseringstools op maat maken',
    'Productieprocessen optimaliseren',
    'Tools ontwikkelen voor verbeterde efficiëntie'
  ],
  contactDescription: "Zin om samen te werken? Of gewoon even sparren over de mogelijkheden?",
  contactParagraph: "Zie je mogelijkheden voor een samenwerking of heb je gewoon wat vragen? Neem gerust contact op voor een vrijblijvend gesprek!",
  backgroundColor: '#f4f4f9',
  titleColor: '#1a1a1a',
  textColor: '#333',
  buttonColor: '#007bff',
  buttonTextColor: '#fff',
  socialLinks: [
    {
      url: 'https://linkedin.com/in/your-profile',
      color: '#0e76a8',
      icon: <AiOutlineLinkedin />,
    },
  ],
};

export const personalia = {
  name: 'Sjoerd Ramaker',
  profilePicUrl: '/images/profielfoto.jpg',
  bio: 'Hoi, ik ben Sjoerd. Website bouwen letsgoooo.',
  contactInfo: {
    email: 'info@ramakersolutions.com',
    phone: '+31 6 38004944',
    linkedin: 'https://www.linkedin.com/in/sjoerd-ramaker-61066b251/',
    location: 'Groningen, Nederland',
  },
  logo: '/images/Ramaker Solutions_E4.jpg',
};

export const projects = [
  {
    title: "Specialist Locatie-Analyse",
    company: "United Rentals",
    startDate: "Sep 2024",
    endDate: "Okt 2024",
    description: [
      'Uitgebreide marktanalyse uitgevoerd om kansrijke regio\'s in Nederland te identificeren',
      'Demografisch onderzoek gedaan en potentiële klantenclusters in kaart gebracht',
      'Strategisch advies ontwikkeld voor optimale vestigingslocaties op basis van bereikbaarheid en marktpotentieel'
    ],
    technologies: ["Marktanalyse", "GIS Mapping", "Data Analytics", "Strategische Planning"]
  },
  {
    title: "Optimalisatie Productieplanning",
    company: "Van der Graaf Trommelmotoren",
    startDate: "Jun 2023",
    endDate: "Aug 2023",
    description: [
      'Uitgebreide analyse uitgevoerd van productieplannings-workflows om operationele efficiëntie te verbeteren',
      'Stakeholder interviews afgenomen om knelpunten en vereisten in kaart te brengen',
      'Tool ontwikkeld en geïmplementeerd voor optimalisatie van de productieplanning'
    ],
    technologies: ["Excel", "Data-analyse"]
  },
  {
    title: "Datavoorbereiding ERP-implementatie",
    company: "Van der Graaf Trommelmotoren",
    startDate: "Jun 2024",
    endDate: "Okt 2024",
    description: [
      'Artikelbestand opgezet voor halffabricaten in het nieuwe systeem',
      'Geautomatiseerde tool ontwikkeld voor het genereren en beheren van artikelnummers',
      'Data uit verschillende legacy systemen samengevoegd en gestandaardiseerd voor de ERP-migratie'
    ],
    technologies: ["Excel", "Data-integratie"]
  },
  // {
  //   title: "Marktonderzoek Japanse Arbeidsmarkt",
  //   company: "International Business Research Groningen",
  //   startDate: "Apr 2024",
  //   endDate: "Jun 2024",
  //   description: [
  //     'Uitgebreid onderzoek gedaan naar kansen op de Japanse arbeidsmarkt',
  //     'Strategisch advies ontwikkeld voor de integratie van Vietnamees talent',
  //     'Wet- en regelgeving rond arbeidsmigratie in kaart gebracht',
  //     'Gedetailleerd plan opgesteld voor markttoetreding'
  //   ],
  //   technologies: ["Excel", "Onderzoeksmethodieken"]
  // },
  {
    title: "Ontwikkeling Enquêteplatform",
    company: "Rijksuniversiteit Groningen (COELO)",
    startDate: "Okt 2023",
    endDate: "Feb 2024",
    description: [
      'Dynamisch enquêteplatform ontworpen met slimme vragenroutering',
      'Systeem ontwikkeld voor gepersonaliseerde antwoordverwerking',
      'Gebruiksvriendelijke interface gecreëerd voor hogere respons'
    ],
    technologies: ["RStudio", "Form 'R", "Databasebeheer"]
  },
  {
    title: "Investeringsanalyse Duurzame Energie",
    company: "Rijksuniversiteit Groningen",
    startDate: "Okt 2023",
    endDate: "Dec 2023",
    description: [
      'Voorspellend model ontwikkeld voor ROI-analyse van duurzame energiesystemen',
      'Real-world energieverbruikspatronen en kostenvariabelen geïntegreerd',
      'Scenarioanalyse tools gemaakt voor verschillende zonne- en batterijconfiguraties'
    ],
    technologies: ["Matlab", "Data-analyse", "Financiële Modellering"]
  }
];

export const experiences = [
  {
    role: "Data Verwerker (Oprichter)",
    company: "Ramaker Solutions",
    startDate: "Jun 2023",
    endDate: "Heden",
    description: [
      'Maatwerkoplossingen ontwikkeld voor data-analyse en procesoptimalisatie',
      'Geautomatiseerde workflows ontworpen en geïmplementeerd voor dataverwerking',
      'Bruikbare inzichten geleverd via uitgebreide data-analyses'
    ],
    achievements: [],
    skills: ["Data-analyse", "Procesoptimalisatie", "Projectmanagement", "Python", "R Studio", "Matlab", "Excel", "Consultancy", "Toolontwikkeling"]
  },
  {
    role: "Deelnemer Strategie Consulting Programma",
    company: "Expedition Strategy",
    startDate: "Nov 2023",
    endDate: "Nov 2023",
    description: [
      'Intensief trainingsprogramma gevolgd bij toonaangevende strategische adviesbureaus',
      'Praktische consultancyvaardigheden ontwikkeld via real-world cases',
      'Samengewerkt met topbureaus waaronder KPMG, OC&C, Roland Berger, Bain & Company, Accenture, Strategy& en IG&H'
    ],
    achievements: [],
    skills: ["Strategische Analyse", "Probleemoplossing", "Business Case Ontwikkeling"]
  },
  {
    role: "Internationaal Student Consultant",
    company: "International Business Research Project Groningen",
    startDate: "Okt 2023",
    endDate: "Sep 2024",
    description: [
      'Leiding gegeven aan onderzoek naar internationale arbeidsmarkten',
      'Grensoverschrijdende arbeidsmogelijkheden tussen Vietnam en Japan geanalyseerd',
      'Strategieën ontwikkeld voor succesvolle markttoetreding'
    ],
    achievements: [],
    skills: ["Marktonderzoek", "Consultancy", "Internationaal Zakendoen", "Data-analyse"]
  },
  {
    role: "Master Technische Bedrijfskunde",
    company: "Rijksuniversiteit Groningen",
    startDate: "Sep 2023",
    endDate: "Heden",
    description: [
      'Verdieping in procesanalyse en optimalisatiemethoden',
      'Specialisatie in productiesysteemontwerp en logistiek management',
      'Onderzoek naar operationele efficiëntie en systeemoptimalisatie'
    ],
    achievements: [],
    skills: ["Procesanalyse", "Operations Research", "Productiemanagement"]
  },
  {
    role: "Pre-master Technische Bedrijfskunde",
    company: "Rijksuniversiteit Groningen",
    startDate: "Sep 2022",
    endDate: "Jul 2023",
    description: [
      'Basiskennis opgedaan in technische bedrijfskunde',
      'Focus op procesanalyse en systeemoptimalisatie',
      'Voorbereiding op de masteropleiding'
    ],
    achievements: [],
    skills: ["Engineering Fundamentals", "Operations Research", "Productiemanagement"]
  },
  {
    role: "Bachelor Biomedische Technologie",
    company: "Rijksuniversiteit Groningen",
    startDate: "Sep 2019",
    endDate: "Jul 2022",
    description: [
      'Specialisatie in medische innovatie en ontwikkeling',
      'Brug geslagen tussen medische vereisten en technische oplossingen',
      'Afstudeeropdracht: Verbetering van Trainingsanalyse via Oppervlakte-elektromyografie',
      '    Nieuwe protocollen ontwikkeld voor spieractiviteitsmeting',
      '    Geautomatiseerde analysetools gecreëerd voor trainingsoptimalisatie'
    ],
    achievements: [],
    skills: ["Medische Technologie", "Biomechanica", "Signaalverwerking", "Onderzoeksmethoden"]
  }
];