import React, { useState, useEffect } from 'react';
import { AiOutlineMail, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { GrLanguage } from "react-icons/gr";
import './Navigation.css';

const Navigation = ({ currentPage, setCurrentPage, language, setLanguage, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (isLangMenuOpen) setIsLangMenuOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handlePageChange = (page) => {
    if (page === 'about') {
      setCurrentPage('home');
      setTimeout(() => {
        const element = document.getElementById('about-me');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      setCurrentPage(page);
      scrollToTop();
    }
    setIsOpen(false);
  };

  const toggleLangMenu = (e) => {
    e.stopPropagation();
    setIsLangMenuOpen(!isLangMenuOpen);
    if (isOpen) setIsOpen(false);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setIsLangMenuOpen(false);
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navContainer = document.querySelector('.nav-wrapper');
      if (navContainer && !navContainer.contains(event.target)) {
        setIsOpen(false);
        setIsLangMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="nav-wrapper">
      <div className="nav-top">
        <div className="nav-toggle">
          <div className="left-section">
            <img 
              src={process.env.PUBLIC_URL + "/images/Ramaker Solutions_Abbr.png"}
              alt="Ramaker Solutions" 
              className="company-logo"
              onClick={() => handlePageChange('home')}
            />
            <button className="toggle-button" onClick={toggleDropdown}>
              {isOpen ? (
                <>
                  <AiOutlineClose className="toggle-icon" />
                  <span>{language === 'en' ? 'Close' : 'Sluiten'}</span>
                </>
              ) : (
                <>
                  <AiOutlineMenu className="toggle-icon" />
                  <span>Menu</span>
                </>
              )}
            </button>
          </div>

          <div className="contact-buttons">
            <div className="language-switcher">
              <button className="language-button" onClick={toggleLangMenu}>
                <GrLanguage className="language-icon" />
              </button>
              {isLangMenuOpen && (
                <div className="language-menu">
                  <button 
                    className={`language-option ${language === 'en' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                  >
                    English <span className="country-code">GB</span>
                  </button>
                  <button 
                    className={`language-option ${language === 'nl' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('nl')}
                  >
                    Nederlands <span className="country-code">NL</span>
                  </button>
                </div>
              )}
            </div>
            <a
              href={`mailto:${data.personalia.contactInfo.email}`}
              className="contact-link email"
            >
              <AiOutlineMail className="contact-icon" />
              <span>{language === 'en' ? 'Contact Me' : 'Contact'}</span>
            </a>
          </div>
        </div>
      </div>

      <div className={`nav-container ${isOpen ? 'open' : ''}`}>
        <div className="nav-content">
          <div className="nav-buttons">
            <button
              className={`nav-button ${currentPage === 'home' ? 'active' : ''}`}
              onClick={() => handlePageChange('home')}
            >
              <div className="button-content">
                <span className="button-title">Home</span>
                <span className="button-description">
                  {language === 'en' ? 'Welcome Page' : 'Welkomstpagina'}
                </span>
              </div>
            </button>

            {/* About Me Button - Commented out for future use
            <button
              className={`nav-button ${currentPage === 'home' ? 'active' : ''}`}
              onClick={() => handlePageChange('about')}
            >
              <div className="button-content">
                <span className="button-title">
                  {language === 'en' ? 'About Me' : 'Over Mij'}
                </span>
                <span className="button-description">
                  {language === 'en' ? 'Personal Background' : 'Persoonlijke Achtergrond'}
                </span>
              </div>
            </button>
            */}
            
            <button
              className={`nav-button ${currentPage === 'projects' ? 'active' : ''}`}
              onClick={() => handlePageChange('projects')}
            >
              <div className="button-content">
                <span className="button-title">
                  {language === 'en' ? 'Projects' : 'Projecten'}
                </span>
                <span className="button-description">
                  {language === 'en' ? 'View My Work' : 'Bekijk Mijn Werk'}
                </span>
              </div>
            </button>
            
            <button
              className={`nav-button ${currentPage === 'experience' ? 'active' : ''}`}
              onClick={() => handlePageChange('experience')}
            >
              <div className="button-content">
                <span className="button-title">
                  {language === 'en' ? 'Experience' : 'Ervaring'}
                </span>
                <span className="button-description">
                  {language === 'en' ? 'My Background' : 'Mijn Achtergrond'}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;