import React, { useState, useRef, useEffect } from 'react';
import { GiFootsteps } from "react-icons/gi";
import CopyrightBeam from '../components/CopyrightBeam';
import './ExperiencePage.css';

const ExperiencePage = ({ experiences, personalia, pageTitle }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  return (
    <div className="experience-page">
      <h1 className="page-title">{pageTitle}</h1>
      <div className="experience-timeline">
        {experiences.map((experience, index) => (
          <ExperienceCard
            key={index}
            experience={experience}
            index={index}
            isExpanded={expandedIndex === index}
            setExpanded={setExpandedIndex}
            isLast={index === experiences.length - 1}
          />
        ))}
      </div>
      <CopyrightBeam 
        name={personalia.name}
        email={personalia.contactInfo.email}
        phone={personalia.contactInfo.phone}
        location={personalia.contactInfo.location}
      />
    </div>
  );
};

const ExperienceCard = ({ experience, index, isExpanded, setExpanded, isLast }) => {
  const cardRef = useRef(null);
  const [footsteps, setFootsteps] = useState([]);
  const [lineDims, setLineDims] = useState({ width: 0, height: 0 });

  const toggleExpand = () => {
    setExpanded(prev => (prev === index ? null : index));
  };

  useEffect(() => {
    const updateLine = () => {
      if (!cardRef.current) return;
      const card = cardRef.current;
      const nextCard = card.parentElement.nextElementSibling?.querySelector('.experience-card');
      if (!nextCard) return;

      const cardRect = card.getBoundingClientRect();
      const nextCardRect = nextCard.getBoundingClientRect();

      // Define vertical start and end points relative to the current card.
      const startY = cardRect.height / 2;
      const endY = nextCardRect.top - cardRect.top;

      let d = "";
      let containerWidth = 0;

      if (index % 2 === 0) {
        // LEFT CARD:
        containerWidth = (nextCardRect.left + nextCardRect.width / 2) - cardRect.right;
        d = `M 0,${startY} h ${containerWidth * 0.4} C ${containerWidth * 0.6},${startY} ${containerWidth * 0.8},${startY} ${containerWidth},${endY}`;
      } else {
        // RIGHT CARD:
        containerWidth = cardRect.left - (nextCardRect.left + nextCardRect.width / 2);
        d = `M ${containerWidth},${startY} h ${-containerWidth * 0.4} C ${containerWidth - containerWidth * 0.6},${startY} ${containerWidth - containerWidth * 0.8},${startY} 0,${endY}`;
      }

      setLineDims({ width: containerWidth, height: endY + 20 });

      // Create a temporary SVG path element to sample points along the curve.
      const svgns = "http://www.w3.org/2000/svg";
      const tempSvg = document.createElementNS(svgns, "svg");
      const tempPath = document.createElementNS(svgns, "path");
      tempPath.setAttribute("d", d);
      tempSvg.appendChild(tempPath);

      const totalLength = tempPath.getTotalLength();

      // Place an icon every fixed 80px along the path.
      const iconSpacing = 65; // Updated spacing value
      const numIcons = Math.floor(totalLength / iconSpacing);
      const newFootsteps = [];
      for (let i = 1; i <= numIcons; i++) {
        const distance = i * iconSpacing;
        const point = tempPath.getPointAtLength(distance);
        // Calculate the tangent for rotation.
        let delta = 1;
        if (distance + delta > totalLength) delta = -1;
        const nextPoint = tempPath.getPointAtLength(distance + delta);
        const angleRad = Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x);
        const angleDeg = angleRad * 180 / Math.PI;
        const iconRotation = angleDeg - 45;
        newFootsteps.push({ x: point.x, y: point.y, rotation: iconRotation });
      }
      setFootsteps(newFootsteps);
    };

    updateLine();
    window.addEventListener('resize', updateLine);
    const observer = new ResizeObserver(updateLine);
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    return () => {
      window.removeEventListener('resize', updateLine);
      observer.disconnect();
    };
  }, [isExpanded, index]);

  return (
    <div className={`experience-container ${index % 2 === 0 ? 'left' : 'right'}`}>
      <div 
        ref={cardRef}
        className={`experience-card ${isExpanded ? 'expanded' : ''}`} 
        onClick={toggleExpand}
      >
        <div className="experience-header">
          <h2 className="role-title">{experience.role}</h2>
          <div className="company-info">
            <span className="company-name">{experience.company}</span>
            <span className="duration">{experience.startDate} - {experience.endDate}</span>
          </div>
        </div>
        <div className={`experience-content ${isExpanded ? 'show' : ''}`}>
          <div className="experience-description">
            <ul className="description-list">
              {experience.description.map((item, i) => (
                <li key={i} className={item.startsWith('    ') ? 'indented' : ''}>
                  {item.trim()}
                </li>
              ))}
            </ul>
          </div>
          {experience.achievements && experience.achievements.length > 0 && (
            <div className="achievements">
              <h3>Key Achievements:</h3>
              <ul className="description-list">
                {experience.achievements.map((achievement, i) => (
                  <li key={i}>{achievement}</li>
                ))}
              </ul>
            </div>
          )}
          {experience.skills && experience.skills.length > 0 && (
            <div className="skills">
              <h3>Skills:</h3>
              <div className="skills-container">
                {experience.skills.map((skill, i) => (
                  <span key={i} className="skill-tag">{skill}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Only render connecting footsteps if this isn’t the last card */}
        {!isLast && (
          <div 
            className={`connecting-line ${index % 2 === 0 ? 'left-to-right' : 'right-to-left'}`}
            style={{ width: `${lineDims.width}px`, height: `${lineDims.height}px` }}
          >
            {footsteps.map((pos, i) => (
              <GiFootsteps 
                key={i} 
                className="footstep-icon" 
                style={{ 
                  left: `${pos.x}px`, 
                  top: `${pos.y}px`, 
                  transform: `translate(-50%, 0%) rotate(${pos.rotation}deg)`
                }} 
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperiencePage;
